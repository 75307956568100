import React, { useState } from "react"
import products from "../../../products"
import { useIntl } from "gatsby-plugin-intl"
import TinyImage from "../../components/tinyImage"
import ReactBnbGallery from "react-bnb-gallery"
import "react-bnb-gallery/dist/style.css"

const ProductIntro = () => {
  const [isGalleryOpen, setIsGalleryOpen] = useState(false)
  const [curCategory, setCurCategory] = useState<string>()
  const intl = useIntl()
  const createPhotos = (category: string | undefined) => {
    if (!category) {
      return []
    }

    const { imageLength, names } = products[category]
    let photos = []
    for (let i = 0; i < imageLength; i++) {
      photos.push({
        photo: require(`../../images/products/${category}/${i}.jpg`),
        caption: names
          ? intl.formatMessage({ id: `products.${category}.sublist.${i}` })
          : undefined,
      })
    }
    return photos
  }

  const openGallery = (category: string) => {
    setIsGalleryOpen(true)
    setCurCategory(category)
  }

  return (
    <div className="products-intro-section">
      <div>
        <ReactBnbGallery
          show={isGalleryOpen}
          photos={createPhotos(curCategory)}
          onClose={() => setIsGalleryOpen(false)}
        />
      </div>
      <div className="pb-3 page-title-container">
        <h2 className="page-title">
          {intl.formatMessage({ id: "products.title" })}
          <div className="gray-underline"></div>
          <div className="orange-underline"></div>
        </h2>
      </div>
      <div className="container">
        <div className="products-items-section">
          {Object.keys(products).map(category => {
            return (
              <div
                className="product-intro-item"
                key={category}
                onClick={() => openGallery(category)}
              >
                <TinyImage
                  key={category}
                  src={`index/products/${category}.png`}
                  className="product-intro-item-image"
                ></TinyImage>
                <div className="product-intro-item-image-underline"></div>
                <div className="product-intro-item-image-background"></div>
                <h5 className="name">
                  {intl.formatMessage({
                    id: `products.${category}.name`,
                  })}
                </h5>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default ProductIntro
