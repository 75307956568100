import React, { useContext } from "react"
import { useDevice } from "../../hooks"
import BigImage from "../bigImage"
import { deviceContext } from "../layout"

const BannerSection = () => {
  const device = useContext(deviceContext)

  return (
    <div className="banner-section">
      <div className="top-right-triangle-section" style={{ top: 0 }}></div>
      <div className="background">
        <BigImage
          src={`products/banner-${device}.jpg`}
          style={{ height: "100%" }}
        ></BigImage>
      </div>
      <div className="bottom-left-triangle-section" style={{ bottom: 0 }}></div>
    </div>
  )
}

export default BannerSection
