import React from "react"
import Layout from "../components/layout"
import { ProductIntro, Banner, More } from "../components/products"
import SEO from "../components/seo"
import "../style/products.scss"

const Products = props => {
  return (
    <Layout {...props}>
      <SEO />
      <div className="products-page">
        <ProductIntro />
        <Banner />
        <More />
      </div>
    </Layout>
  )
}

export default Products
