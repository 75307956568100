import React from "react"
import { Link, useIntl } from "gatsby-plugin-intl"

const More = () => {
  const intl = useIntl()

  return (
    <Link to={"/contact"}>
      <h4 className="more m-0">
        {intl.formatMessage({ id: "products.more.title" })} →
      </h4>
    </Link>
  )
}

export default More
